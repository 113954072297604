import React, {useEffect} from 'react';
import InfiniteView from "../../../components/InfiniteView";
import {useSelector} from "react-redux";
import {getExponentFilter, getExponents, getExponentSearch, getExponentsFetching, getExponentsHasMore} from "../../CongressAE2024/Exponents/reducer";
import {
    clearCountryFilter,
    clearSearch,
    clearSegmentFilter,
    EXPONENTS_PAGE_COUNT,
    fetchExponents,
    fetchSegments
} from "../../CongressAE2024/Exponents/action";


import { ReactComponent as GeoPoint } from '../../../icons/geo-point.svg';

import {Flag} from "semantic-ui-react";
import BookmarkComponent from "../../../components/Bookmark";
import {Base64} from "../../../App";
import {setWindowScrollPosition} from "../../../components/WindowDimensions";
import SadIcon from "../../../icons/sad-icon.png";
import translations from "../../../components/LanguageModal/translations";
import {getLanguage} from "../../../components/LanguageModal/reducer";

import { ReactComponent as Arrow } from '../../../icons/arrow-icon.svg';

export default function ExponentsArchive(props) {

    const { dispatch, filter, exponents: incomeExponents, routeAction, isPageReloaded, token, mixpanel } = props;

    const exponents = incomeExponents ?? useSelector((state) => getExponents(state.exponents));
    const isFetching = useSelector((state) => getExponentsFetching(state.exponents));
    const hasMore = useSelector((state) => getExponentsHasMore(state.exponents));
    const filterExponents = useSelector((state) => getExponentFilter(state.exponents));
    const searchExponents = useSelector((state) => getExponentSearch(state.exponents));

    const expFilter = filter ??
        {
            countries: filterExponents.country ?? [],
            segments: filterExponents.segment ?? [],
        }

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {
            readExponents(1)
            setWindowScrollPosition();
        }
        dispatch(clearSearch());
        if (mixpanel) {
            mixpanel.track('View. Exponents')
        }
    }, []);

    useEffect(() => {
            readExponents(1);

    }, [filterExponents, searchExponents]);

    const readExponents = (page)  => {
        const params = {
            page,
            per_page: EXPONENTS_PAGE_COUNT,
            order: 'asc',
            orderby: 'upper(name)',
            event: 'ASIAEXPO 2023',
            lang: languageString,
        };

        if (expFilter && (expFilter.countries.length > 0 || expFilter.segments.length > 0)) {
            params['filter'] = Base64.encode(JSON.stringify(expFilter));
        }

        if (searchExponents && searchExponents.length >= 3 ) {
            params['search'] = Base64.encode(JSON.stringify(searchExponents));
        }


        dispatch(fetchExponents(token, params));

        if (page === 1) dispatch(fetchSegments({
            hide_empty: true,
            order: 'asc',
            orderby: 'name',
            lang: languageString,
        }));
    }

    const loadMore = () => readExponents(Math.round(exponents.length / EXPONENTS_PAGE_COUNT) + 1);

    const ExponentCard = (props) => {
        const { exponent, navigate } = props;

        const segmentsExponent = exponent.segments ? exponent.segments.map(elem => elem.name).join(', ') : '';

        const locationExponent = exponent.events && exponent.events.active ? exponent.events.active.map(elem => elem.stand_number).join(", ") : '';
        // const archiveExponent = exponent.events && exponent.events.archive ? exponent.events.archive.map(elem => elem.event).join(", ") : '';
        // const activeExponent = exponent.events && exponent.events.active ? exponent.events.active.map(elem => elem.event).join(", ") : '';

        return (
            <>
                <div className="card-exponent">
                    <div className='bio-exponent'>
                        <img src={exponent.logo} className='img-exponent'/>
                        <div className='column-bio-exp'>
                            <div className='first-row-bio'>
                                <GeoPoint/>
                                <span className='location-exponent-text'>{locationExponent}</span>
                                <Flag name={exponent.country.toLowerCase()} className='flag-exponent'/>
                                <div className="bookmark-exponent">
                                    <BookmarkComponent
                                        bookmark_using_for="exponents"
                                        id={exponent.id}
                                        {...props}
                                    />
                                </div>
                            </div>
                            <span
                                className='exponent-name'>{languageString === "ru" ? exponent.name : exponent.name_en}</span>
                        </div>
                    </div>
                    <span
                        className='shortdesc-exponent'>{languageString === "ru" ? exponent.excerpt : exponent.excerpt_en}</span>
                    <div className='more-container'
                         onClick={() => navigate(`/archive-events/congress-asiaexpo-2023/exponents/exponents-card/${exponent.id}`)}>
                        <span className='more-text'>{t("details")}</span>
                        <Arrow/>
                    </div>
                </div>
            </>
        )
    }

    const ExponentsList = (props) => {
        const {exponents, title} = props;
        return (
            !navigator.onLine ?
                <div className='view-bookmarks'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{t("noExponents")}</span>
                </div>
                : <div className="container exponents filter-opened">
                    {exponents ? exponents.map((element) => (
                        <ExponentCard
                            key={element.id}
                            exponent={element}
                            showLoader
                            {...props}
                        />
                    )) : ''}
                </div>
        );
    }

    return (
        <>
            <InfiniteView
                dataLength={exponents.length}
                nextFetch={loadMore}
                hasMore={hasMore}
                dataList={(
                    <ExponentsList
                        exponents={exponents}
                        {...props}
                    />
                )}
                hideEndMessage={true}
                mustAuth={false}
                isLoading={isFetching}
                loaderText={t("loadExponents") + "..."}
                zeroText={!isFetching ? (
                    <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{t("noExponents")}</span>
                    </div>
                ) : ""}
            />
        </>
    );
};



